import './DotsButtons.scss';

export function DotsButtons(props) {

    const { length, onClick } = props

    const dots = [];

    for (let index = 0; index < length; index++) {
        dots.push(<div className='dot-holder' key={index}>
            <button className="dot-button" id={index} onClick={onClick}></button>
        </div>);
    }

    return (
        <div className="react-carousel-dots-holder">
            {dots}
        </div>
    )
}

